// @ts-nocheck

import { env as envPublic } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';

// Dynamically import publisher-specific translations
const { hooksTranslations = {} } = await import(`$lib/publishers/${envPublic.PUBLIC_PUBLISHER}/redirects.js`);

/**
 * Reroute based on the publisher and the pathname.
 *
 * @param {{ url: URL }} param0
 * @returns {string} The new path after translation
 */
export function reroute({ url }) {
  const publisher = envPublic.PUBLIC_PUBLISHER;
  const pathname = url.pathname;
  const isDocsPath = pathname.startsWith('/docs');

  const prefix = isDocsPath ? '' : `/${publisher}`;

  const segments = pathname.split('/');
  let suffix = '';
  let basePath = pathname;

  const lastSegment = segments.at(-1);
  if (lastSegment && /\.\w+$/.test(lastSegment)) {
    suffix = '/' + lastSegment;
    basePath = segments.slice(0, -1).join('/');
  }

  if (basePath === '/') {
    return prefix + basePath + suffix;
  }

  if (hooksTranslations[basePath]) {
    return prefix + hooksTranslations[basePath] + suffix;
  }

  for (const [key, translatedKey] of Object.entries(hooksTranslations)) {
    if (basePath.startsWith(key)) {
      const remainingPath = basePath.slice(key.length);
      if (remainingPath === '' || remainingPath.startsWith('/')) {
        return prefix + translatedKey + remainingPath + suffix;
      }
    }
  }

  if (Object.keys(hooksTranslations).some((key) => basePath.startsWith(key))) {
    Sentry.captureException(new Error(`Failed to redirect: Route not found in translations for publisher: ${publisher}, path: ${basePath}`));
  }

  return prefix + basePath + suffix;
}
